tfoot.footable-pagination tr td {
  text-align: center;
}

.footable-nav, .pagination {
	list-style: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}

  .footable-nav li, .pagination li {
	  display: inline-block;
    margin-right: 5px;
  }

    .footable-nav li a, .pagination li a, .pagination li span {
	    display: inline-block;
	    padding: 5px 10px;
	    text-decoration: none;
	    color: #0066cc;
      font-weight: bold;
      background-color: transparent;
      border: none;
    }

   .pagination li:last-child span, .pagination li:first-child span {
     background-color: transparent;
     border: none;
   }
    
  .pagination li:last-child span:hover, .pagination li:first-child span:hover {
    background-color: transparent;  
  }
  
  .footable-nav .footable-page-current, .pagination .active span {
	  background: #888;
    border-radius: 50% !important;
    border: none;
  }

    .footable-nav .footable-page-current a, .pagination .active span a {
	    color: #fff;
    }