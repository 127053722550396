.nmpd-wrapper {display: none;}
.nmpd-target {cursor: pointer;}
.nmpd-grid {position:absolute; left:50px; top:50px; z-index:5000; -khtml-user-select: none; border-radius:10px; padding:10px; width: initial;}
.nmpd-overlay {z-index:4999;}
input.nmpd-display {text-align: right;}

.nmpd-grid {border: none; padding: 20px;}
.nmpd-grid>tbody>tr>td {border: none;}

.qtyInput {
	display: block;
	width: 100%;
	padding: 6px 12px;
	color: #555;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}