/*
 * Title: Default Theme
 * Description: Default Theme for The InnerCity Mission Fund Raising Platform
 * @Author Praise-God Emerenini <praisegeek@gmail.com>
 */
/*#################################################################################*/
/*                                BACKGROUNDS                                      */
/*#################################################################################*/
.button-secondary,
.navbar-default .nav .active > a:hover,
.navbar-default .nav li > a:hover,
.banner .carousel-caption button {
  background: #51BFFA; }

/*#################################################################################*/
/*                                   LANDING PAGE                                  */
/*#################################################################################*/
.hasLanding {
  background: #51BFFA !important;
  bottom: 20%; }
  .hasLanding .form-control {
    border-color: #1771A3; }
  .hasLanding .nav > li > a {
    color: #b4e3fd !important; }
  .hasLanding a:hover,
  .hasLanding a:active {
    background: #51BFFA !important;
    color: #e6f6fe !important; }
  .hasLanding li.getstarted a,
  .hasLanding li.closestarted a {
    color: white !important; }

#landingPage {
  background: #51BFFA;
  height: 70%;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative; }
  #landingPage a {
    color: #e6f6fe; }
  #landingPage .arrow {
    position: absolute;
    left: 50%;
    top: -15px;
    z-index: 10;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1); }
  #landingPage .wrapper {
    background: none;
    position: relative; }
    #landingPage .wrapper .left {
      background: #fff !important;
      font-family: HelveticaNeue;
      left: 0px;
      top: 100px;
      bottom: 0;
      height: 100%;
      padding: 20px;
      box-shadow: 0 0px 1px #555;
      border-radius: 5px; }

/*#################################################################################*/
/*                                   BORDERS                                       */
/*#################################################################################*/
.outline,
.nav-tabs > li > a .label {
  border: 1px solid #51BFFA; }

/* border bottom */
.trending-content h2.header:before {
  border-bottom: 1px solid #51BFFA; }

/*border-left */
.project .leftbar .list-group-item.active,
.project .leftbar .list-group-item.active:focus,
.project .leftbar .list-group-item.active:hover {
  border-left: 1px solid #51BFFA; }

/*#################################################################################*/
/*                                   COLORS                                        */
/*#################################################################################*/
.trending-content .media .media-body .duration li > span,
.trending .sidebar ul > li.active,
.popular .duration li > span,
#project-updates-menu .navbar-nav .active a,
.profile .profile-wall .nav-tabs > li > a,
.subheader .content h2 {
  color: #51BFFA; }

/*#################################################################################*/
/*                                   SECTIONS                                       */
/*#################################################################################*/
.trending,
.project,
.project-updates,
.search-results {
  background: #F8F8F8; }

.howitworks {
  background: #1d90d0; }
  .howitworks .stepsWrapper .step span {
    color: #c3e4f6; }
  .howitworks .stepsWrapper .step .icon {
    background: #1779AC; }

/*#################################################################################*/
/*                                   FOOTER                                        */
/*#################################################################################*/
.footer {
  background: #1d90d0; }

.footer-copyright {
  background: #1a80b9;
  border-top: 1px solid #1771A3;
  color: #F3F1AB; }

.footer a {
  color: #e0f4ff; }

.footer .social ul li, .footer-copyright .scrollup {
  background: #1771A3;
  border: 1px solid #69bcea; }

/*#################################################################################*/
/*                                   BORDER-RADIUS                                 */
/*#################################################################################*/
.button-secondary,
.button-primary,
.button-default,
.input-default,
.pay-btn,
.no-radius,
.banner .carousel-caption button,
.trending-content h2.header > a,
.howitworks .stepsWrapper .step {
  border-radius: 5px; }

/*#################################################################################*/
/*                                   PROFILE WALL                                  */
/*#################################################################################*/

/*# sourceMappingURL=default.theme.css.map */
