/*
 * Title: Site Stylesheet
 * Description: Stylesheet for The InnerCity Mission Fund Raising Platform
 * @Author Praise-God Emerenini <praisegeek@gmail.com>
 * @Co-Author Ifeoluwa Odewale  <ifeoluwa.odewale@gmail.com>
 */
/*Mixin*/
.sidebar {
  box-sizing: border-box; }
  .sidebar *, .sidebar *:before, .sidebar *:after {
    box-sizing: inherit; }
  .sidebar img {
    height: 90px !important;
    padding: 5px; }
  .sidebar img.play {
    position: absolute !important;
    left: 35%;
    top: 33%;
    z-index: 5;
    width: 35px;
    height: 35px !important; }
  .sidebar .slide-img-wrapper {
    position: relative; }
  .sidebar .slider.responsive.slick-initialized.slick-slider.slick-dotted {
    margin-left: -1.8em;
    max-width: 365px; }
  .sidebar .col-sm-12.heroSlider-fixed {
    height: 130px !important;
    overflow: hidden !important; }
  .sidebar .slick-slide {
    width: auto  !important;
    margin-left: 10px;
    height: 90px !important;
    overflow: hidden !important; }
  .sidebar .slick-dots {
    bottom: -40px; }

.slick-dots {
  text-align: center;
  margin: 0 0 10px 0;
  padding: 0; }
  .slick-dots li {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px; }
    .slick-dots li.slick-active button {
      background-color: black; }
    .slick-dots li button {
      font: 0/0 a;
      text-shadow: none;
      color: transparent;
      background-color: #999;
      border: none;
      width: 15px;
      height: 15px;
      border-radius: 50%; }
    .slick-dots li :hover {
      background-color: black; }

/* Custom Arrow */
.sidebar .prev {
  color: #999;
  position: absolute;
  top: 38%;
  left: -2em;
  font-size: 1.5em; }
  .sidebar .prev :hover {
    cursor: pointer;
    color: black; }

.sidebar .next {
  color: #999;
  position: absolute;
  top: 38%;
  right: -2em;
  font-size: 1.5em; }
  .sidebar .next :hover {
    cursor: pointer;
    color: black; }

@media screen and (max-width: 450px) {
  .sidebar .next {
    display: none !important; } }

body {
  padding-top: 50px;
  font-family: "Segoe UI", Candara, "Bitstream Vera Sans", "DejaVu Sans", "Bitstream Vera Sans", "Trebuchet MS", Verdana, "Verdana Ref", sans-serif; }

/* recaptcha Responsive*/
.recaptcha-small #rc-imageselect, .recaptcha-small .g-recaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0; }

@media screen and (max-height: 575px) {
  #rc-imageselect, .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

/* ends recaptcha Responsive */
h1.big-heading,
h3.big-heading {
  font-size: 36px;
  font-family: HelveticaNeue-Bold; }

/* background */
.bg-theme {
  background: #f8f8f8; }

.modal-open .modal {
  position: fixed;
  top: 20%; }

/* icon-sets */
.flag-icon {
  border-radius: 3px; }

/* Landing page */
/* icon fontface */
.fa.fa-yookos:before,
.fa.fa-yookos:after {
  content: '\c192';
  font-size: 12px; }

/* typorgraphy */
.heading {
  font-family: Tahoma;
  color: #AAA;
  font-size: 22px;
  font-weight: 200;
  margin: 30px 0px; }

.text-muted-dark {
  color: #333; }

.text-muted-dark:hover {
  text-decoration: none; }

.text-shadow {
  text-shadow: 0 1px 0 #000; }

.text-muted-light {
  color: #bebebe !important; }

.text-muted-white {
  color: #fff !important; }

.text-white {
  color: #FDFDFD;
  text-shadow: 0 1px 0 #000; }

.link-dark {
  color: #000000; }

.no-transform {
  text-transform: capitalize; }

.use-flex {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

.bg-white {
  background: #fff; }

.box-shadow {
  box-shadow: 0 1px 1px #ddd; }

.no-radius {
  border-radius: 0px !important; }

.padding {
  padding: 10px; }

.margin {
  margin: 10px; }

.small, .thin {
  font-size: 14px; }

.img-rounded-sm {
  -o-border-radius: 3px !important;
  border-radius: 3px !important; }

/* Auto Responsive image on summernote */
.p_about {
  width: 100% !important; }

.p_about img {
  display: inline-block;
  max-width: 100%;
  height: auto; }

/* carousel */
.carousel-fade .carousel-inner .item {
  transition-property: opacity; }

.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0; }

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1; }

.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.carousel-fade .carousel-control {
  z-index: 2; }

/* Progress bar */
.graph-small {
  position: relative;
  margin: 20px;
  width: 50px;
  height: 50px; }
  .graph-small canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }
  .graph-small span {
    color: #555;
    display: block;
    line-height: 50px;
    text-align: center;
    width: 50px;
    font-family: sans-serif;
    font-size: 12px;
    font-weight: 100;
    margin-left: 0px; }
  .graph-small input {
    width: 70px; }

.graph-large {
  position: relative;
  width: 100px;
  height: 100px; }
  .graph-large canvas {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }
  .graph-large span {
    color: #9B9B9B;
    display: block;
    line-height: 100px;
    text-align: center;
    width: 100px;
    font-family: HelveticaNeue, sans-serif;
    font-size: 16px;
    margin-left: 0px; }
  .graph-large input {
    width: 160px; }

/* forms */
form h3 {
  font-size: 28px;
  font-family: HelveticaNeue-Thin, Tahoma, Arial;
  margin-bottom: 40px;
  font-weight: 200; }

form h3 > p {
  font-size: 14px;
  color: #6F7274;
  text-transform: none;
  font-family: Tahoma; }

form .form-group small {
  font-weight: 200; }

/* button */
.btn-info {
  background: #3097D1;
  border: 1px solid #1771A3; }

.button-secondary {
  background: #51BFFA;
  border-radius: 50px;
  font-family: HelveticaNeue-Light;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  padding: 10px 20px; }

.button-primary {
  background: #4A4A4A;
  border-radius: 50px;
  font-family: HelveticaNeue-Light;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  padding: 10px 20px; }

.button-secondary:hover,
.button-primary:hover {
  color: #FFFFFF;
  opacity: 0.9; }

.button-default {
  border: 1px solid #E7E7E7;
  border-radius: 50px;
  font-family: HelveticaNeue-Bold, Tahoma, Arial;
  font-size: 12px;
  color: #A0A3A5;
  padding: 10px 20px; }

.input-default {
  border: 1px solid #E7E7E7;
  border-radius: 50px;
  font-family: HelveticaNeue-Thin, Tahoma, Arial;
  font-size: 14px; }

a.input-default {
  color: #A0A3A5; }

.selector-btn {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 0px;
  padding: 10px 20px;
  margin: 10px 0px;
  width: 200px;
  text-align: center;
  font-size: 12px !important;
  color: #999; }

.selector-btn.active {
  box-shadow: 0px;
  background: none;
  box-shadow: inset 0 -3px 0 #5CB85C; }

.pay-btn {
  border-radius: 0px; }

/* Links */
a.link:hover {
  text-decoration: none; }

.hover:hover {
  opacity: 0.7; }

.panel-title .link {
  color: #068;
  text-transform: uppercase; }

/* Select */
.small-select.form-control {
  width: 40%; }

/* Shortcuts overrides */
.no-gutter {
  padding: 0px;
  margin: 0px; }

.no-bg {
  background: none !important; }

.bg-striped {
  background: #f5f5f5 !important; }

.margin-left {
  margin-left: 10px !important; }

.thumb-image {
  width: 100%; }

.link-btn {
  background: none;
  border: none; }

.link-btn:hover {
  opacity: 0.7; }

.badge {
  background: #A7A7A7; }

.no-spacing {
  margin: 0px; }

.no-radius {
  border-radius: 5px; }

.small-header {
  font-size: 12px;
  margin: 5px 0px; }

.outline {
  background: none !important;
  border: 1px solid #51BFFA;
  color: #51BFFA; }

.outline:hover {
  color: #222222; }

.thin {
  font-family: HelveticaNeue-Light, Tahoma, Arial;
  font-size: 14px !important;
  font-weight: 200 !important; }

.medium {
  font-size: 14px; }

/* form errors */
.form-errors li {
  margin-left: 10px; }

/* panel */
.panel {
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); }

/* Forms */
.form-flat .form-control {
  border-radius: 0px; }

/* Update sidebar */
.updates-sidebar {
  padding: 20px; }

.updates-sidebar h4:not(.text-muted) {
  color: #f8f8f8;
  font-family: HelveticaNeue-Thin;
  font-size: 16px;
  font-weight: 200; }

.update-form {
  background: #fff;
  padding: 20px 20px 2px 20px;
  box-shadow: 0 1px 1px #ddd;
  margin-bottom: 10px; }

/* Nav Tabs */
.profile .nav-tabs li {
  margin-right: 20px; }

.campaign-view .nav-tabs {
  padding-top: 20px;
  padding-bottom: 0px;
  border-bottom: none; }

.campaign-view .nav-tabs > li > a,
.campaign-view .nav-tabs > li > a:focus {
  padding-bottom: 20px; }

.nav-tabs > li > a,
.nav-tabs > li > a:focus {
  margin-right: 0px;
  padding-left: 0px;
  font-family: HelveticaNeue;
  font-size: 16px;
  background-color: transparent;
  border: none;
  color: #000;
  box-shadow: inset 0 -1px 0 #dddddd;
  transition: all 0.2s; }

.nav-tabs > li > a .label {
  background: none;
  border: 1px  solid #51BFFA;
  color: #7b7b7b;
  font-weight: 200;
  position: relative;
  top: -1px;
  left: 15px; }

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus:hover {
  background-color: transparent;
  box-shadow: inset 0 -2px 0 #2196f3;
  color: #2196f3; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus {
  border: none;
  box-shadow: inset 0 -2px 0 #2196f3;
  color: #2196f3; }

.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus:hover {
  border: none;
  color: #2196f3; }

.nav-tabs > li.disabled > a {
  box-shadow: inset 0 -1px 0 #dddddd; }

.nav-tabs.nav-justified > li > a,
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus,
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border: none; }

.nav-tabs .dropdown-menu {
  margin-top: 0; }

/* Navbar */
.navbar-default {
  margin-bottom: 0px;
  background: #3097D1;
  border: none;
  box-shadow: inset 0px -1px 0 #1771A3; }
  .navbar-default .form-control {
    border-color: #1771A3; }
  .navbar-default .navbar-collapse {
    color: #fff; }

.navbar-toggle, .navbar-toggle.collapsed {
  background: transparent !important;
  border: none !important;
  color: #fff !important; }
  .navbar-toggle .icon-bar, .navbar-toggle.collapsed .icon-bar {
    background-color: #fff !important; }

.navbar-fixed-bottom .navbar-collapse.in, .navbar-fixed-top .navbar-collapse.in {
  min-height: 900px !important; }
  .navbar-fixed-bottom .navbar-collapse.in .nav > li .dropdown-menu a, .navbar-fixed-top .navbar-collapse.in .nav > li .dropdown-menu a {
    color: #fff; }

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #1771A3; }

.navbar-default .nav .navbar-form {
  border: none; }

.navbar-default .badge {
  position: relative;
  top: -10px;
  background: #F65C5F;
  width: 18px;
  height: 16px;
  padding: 1px;
  padding-bottom: 2px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 200;
  border: 1px solid red;
  left: -12px; }

.navbar-default .nav > li:not(.dropdown) > a {
  padding-top: 15px;
  position: relative;
  color: #fff !important;
  font-size: 13px; }

.navbar-default .nav > li.dropdown > a {
  color: #fff;
  font-size: 13px; }

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
  background-color: #68B7E3; }

.navbar-default .nav .dropdown-toggle {
  padding-top: 7px;
  padding-bottom: 7px; }

.navbar-default .nav .notification-menu {
  width: 457px; }
  .navbar-default .nav .notification-menu > li {
    position: relative; }
    .navbar-default .nav .notification-menu > li > a:hover {
      background: #fff;
      color: #000; }
    .navbar-default .nav .notification-menu > li .new {
      position: absolute;
      top: -10px;
      right: 0;
      color: #fff;
      width: 0;
      height: 0;
      border-top: 10px solid #5aacda;
      border-left: 10px solid transparent; }
      .navbar-default .nav .notification-menu > li .new .fa {
        position: relative;
        top: -25px;
        font-size: 10px;
        right: 10px;
        display: none; }

.navbar-default .nav .active > a {
  font-weight: 700;
  border: none;
  background: #1771A3; }

.navbar-default .nav .active > a:hover,
.navbar-default .nav li > a:hover {
  background-color: #51BFFA;
  opacity: 0.7;
  color: #fff; }

.navbar-default .navbar-form .has-feedback .glyphicon {
  color: #bebebe; }

/* Side menu */
.dropdown-sidemenu > li {
  margin: 0px;
  padding-left: 0px;
  border-left: 1px solid #EAEAEA; }

.dropdown-sidemenu > li:first-child {
  padding-top: 0px;
  margin-top: 0px; }

.dropdown-sidemenu > li.active {
  box-shadow: -4px 0 0 #51BFFA; }

.dropdown-sidemenu > li > a {
  font-family: 'HelveticaNeue-Light', Tahoma, Arial;
  font-size: 14px;
  color: #0E1A06 !important;
  line-height: 25px;
  text-transform: capitalize;
  background: none !important; }

.dropdown {
  z-index: 100; }

#advance-search {
  margin-top: -30px;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 100px;
  background: #59ABE3; }

#advance-search h1 {
  color: #f7f7f7;
  text-shadow: 0 1px 0 #000; }

#advance-search .has-feedback {
  width: 60%;
  margin: 0 auto;
  margin-top: 50px; }

/* Banner Section */
.banner {
  background: transparent;
  border-bottom: 1px solid #ddd; }

.banner .container-fluid {
  padding-left: 0;
  padding-right: 0; }

.banner .carousel img {
  width: 100%; }

.banner .carousel-caption {
  top: 25%; }

.banner .carousel-caption h1 {
  font-family: 'HiraKakuStd-W8';
  font-size: 32px;
  color: #FFFFFF;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); }

.banner .carousel-caption p {
  font-family: 'Helvetica-Light';
  font-size: 14px;
  color: #E7E7E7;
  line-height: 17px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); }

.banner .carousel-caption button {
  background-color: #51BFFA;
  border-radius: 20px;
  color: #FFF;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 20px;
  font-family: 'HelveticaNeue-Light', Tahoma, Arial;
  margin-top: 20px;
  opacity: .9; }

.banner .carousel-caption button:hover {
  opacity: 1; }

.banner-campaign-bg {
  background: url("/images/banner-07.jpg") no-repeat 0 0;
  background-size: cover;
  opacity: .6;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px; }

.banner-campaign-items {
  height: 462px !important; }
  .banner-campaign-items .titles {
    font-size: 18px;
    color: #ddd !important;
    position: relative;
    top: 70px;
    z-index: 100; }
  .banner-campaign-items .offset {
    position: relative;
    top: -30px;
    z-index: 10; }
  .banner-campaign-items .banner-item-wrapper:first-child {
    margin-right: -20px;
    -webkit-transform: rotateY(10deg);
    transform: rotateY(10deg); }
  .banner-campaign-items .banner-item-wrapper:last-child {
    margin-left: -20px;
    -webkit-transform: rotateY(-10deg);
    transform: rotateY(-10deg); }
  .banner-campaign-items .banner-item {
    position: relative;
    top: 120px; }
    .banner-campaign-items .banner-item .panel-default {
      background: transparent; }
    .banner-campaign-items .banner-item .panel-body {
      padding: 0px;
      height: 160px;
      overflow: hidden;
      -o-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0; }
      .banner-campaign-items .banner-item .panel-body img {
        width: 100%; }
    .banner-campaign-items .banner-item .panel-footer {
      text-shadow: none; }
      .banner-campaign-items .banner-item .panel-footer .info h3 {
        font-size: 14px;
        font-weight: 200; }
      .banner-campaign-items .banner-item .panel-footer .info p {
        text-shadow: none;
        color: #444;
        font-size: 12px; }

/*carousel*/
.carousel-indicators {
  bottom: 0; }

.carousel-control.right,
.carousel-control.left {
  background-image: none; }

.carousel .item {
  min-height: 350px;
  height: 100%;
  width: 100%; }

.carousel-caption h2 {
  position: relative;
  top: 15px;
  text-transform: capitalize;
  color: #fff0aa;
  font-size: 22px; }

.carousel .icon-container {
  display: inline-block;
  font-size: 25px;
  line-height: 25px;
  padding: 1em;
  text-align: center;
  border-radius: 50%; }

/* Animation delays */
.carousel-caption h2:first-child {
  -webkit-animation-delay: 2s;
  animation-delay: 1s; }

.carousel-caption h2:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s; }

.carousel-caption a {
  -webkit-animation-delay: 1s;
  animation-delay: 1s; }

/* Group Section */
.groups {
  background: #fff;
  position: relative; }
  .groups .nav-tabs.nav > li:not(:first-child) > a {
    padding: 10px 20px !important;
    border-left: 1px solid #ddd;
    border-bottom: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 300;
    color: #444; }
  .groups .nav-tabs.nav > li:first-child > a {
    border-bottom: none !important;
    box-shadow: none; }
  .groups .nav-tabs.nav li.active a {
    color: #51BFFA; }

.groups.affix {
  position: fixed;
  top: 49px;
  left: 0;
  right: 0;
  z-index: 100;
  background: #fff; }

.groups.affix + .groups-content .home-first-row {
  padding-top: 72px !important; }

.home-first-row {
  padding-top: 30px;
  padding-bottom: 30px; }

.groups-content .awareness,
.groups-content .events,
.groups-content .programs {
  background: #f8f8f8; }

/* Trending Section */
.trending {
  background: #F8F8F8;
  padding-top: 30px;
  padding-bottom: 30px; }

.trending-content {
  /* Trending content */
  margin-top: 0px; }

.trending-content h2.header {
  color: #888;
  font-weight: 400;
  position: relative;
  padding-bottom: 0px;
  display: inline-block;
  text-transform: uppercase;
  width: 100%; }

.trending-content h2.header:before {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  width: 5%;
  border-bottom: 2px solid #51BFFA; }

.trending-content h2.header > span {
  color: #51BFFA; }

.trending-content h2.header > a {
  text-transform: none;
  border: 1px solid #888;
  border-radius: 50px;
  font-size: 14px;
  color: #888;
  padding: 10px 20px; }

.trending-content .media .media-left {
  position: relative; }

.trending-content .media .media-left img.ribbon {
  position: absolute;
  right: 7px;
  top: -5px;
  padding: 0; }

.trending-content .media .media-body {
  padding-left: 20px; }

.trending-content .media .media-body h4.media-heading {
  line-height: 19px; }

.trending-content .media .media-body h4.media-heading > p {
  color: #6F7274;
  font-size: 12px; }

.trending-content .media .media-body .location {
  margin-top: 50px; }

.trending-content .media .media-body .location li {
  color: #6F7274;
  font-size: 12px; }

.trending-content .media .media-body .location li > span {
  padding-right: 5px; }

.trending-content .media .media-body .duration li {
  text-align: left; }

.trending-content .media .media-body .duration li > h5 {
  line-height: 0px;
  color: #6F7274; }

.trending-content .media .media-body .duration li > span {
  text-align: center;
  font-size: 16px;
  color: #666 !important;
  font-family: Tahoma; }

.home-first-row .sidebar {
  /* Trending Sidebar */
  margin-top: 22px; }

.home-first-row .sidebar ul > li {
  margin: 0px;
  padding-left: 10px;
  padding-bottom: 5px;
  box-shadow: -1px 0 #ccc; }

.home-first-row .sidebar ul > li:first-child {
  padding-top: 0px;
  margin-top: 0px; }

.home-first-row .sidebar ul > li.active {
  box-shadow: -3px 0 0 #51BFFA; }

.home-first-row .sidebar ul > li > a {
  font-family: 'HelveticaNeue-Light', Tahoma, Arial;
  font-size: 14px;
  color: #0E1A06;
  line-height: 25px; }

/* Popular Section */
.popular {
  background: #EAEAEA;
  padding-top: 30px;
  padding-bottom: 30px; }

.popular h2.header {
  color: #666666;
  font-weight: 400;
  font-size: 22px;
  position: relative;
  padding-bottom: 0px;
  display: inline-block;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 50px; }

.popular h2.header:before {
  content: "";
  position: absolute;
  height: 1px;
  bottom: 0;
  left: 0;
  width: 5%;
  top: 40px;
  border-bottom: 2px solid #51BFFA; }

body section#guided {
  /*
  background: linear-gradient(to right, #999999, #cccccc, #999999);
  background: -webkit-linear-gradient(to right, #999999, #cccccc, #999999);
  background: -moz-linear-gradient(to right, #999999, #cccccc, #999999);
  background: -o-linear-gradient(to right, #999999, #cccccc, #999999);
  background: -ms-linear-gradient(to right, #999999, #cccccc, #999999);*/
  background: white; }

body section#guided h2.header:before {
  border-bottom: 0px; }

body section#guided h2.header {
  font-size: 40px;
  font-weight: bolder;
  color: white;
  text-shadow: 1px 1px 10px #444; }

body section#guided #word-wrapper {
  border: 2px solid #3097d1;
  padding: 20px;
  border-radius: 10px; }

.popular h2.header > span {
  color: #51BFFA; }

.popular h2.header > a {
  text-transform: none;
  border: 1px solid #E7E7E7;
  border-radius: 50px;
  font-size: 12px;
  color: #A0A3A5;
  padding: 10px 20px; }

.popular .item .panel-body, .item-wrapper .panel-body {
  padding: 0;
  height: 170px !important;
  overflow: hidden;
  background: none; }

.popular .panel-footer {
  background-color: #fff; }

.popular .item .panel-body img {
  border-radius: 3px 3px 0 0; }

.popular .item .info h3 {
  font-family: 'HelveticaNeue-Bold', Tahoma, Arial;
  font-size: 14px;
  color: #1cf;
  line-height: 14px; }

.popular .item .info h3 > p {
  font-family: 'HelveticaNeue-Regular', Tahoma,Arial;
  color: #6F7274;
  font-weight: normal;
  font-size: 13px; }

.popular .item .info .excerpt {
  font-family: 'HelveticaNeue-Regular', Tahoma,Arial;
  color: #6F7274;
  font-size: 14px; }

.popular .location {
  margin-top: 40px; }

.popular .location li {
  color: #6F7274;
  font-size: 12px; }

.popular .location li > span {
  padding-right: 1px;
  font-size: 12px; }

.popular .duration {
  margin-top: 10px; }

.popular .duration li > h5 {
  line-height: 0px;
  color: #6F7274;
  font-size: 12px; }

.popular .duration li {
  text-align: center; }

.popular .duration li > span {
  color: #777 !important;
  font-weight: 600;
  font-size: 12px;
  text-align: center; }

/* How it works section */
.howitworks {
  background: #494949;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: inset 0px 1px 0 #1771A3; }

.howitworks h1 {
  font-family: 'HelveticaNeue-Thin', Tahoma, Arial;
  font-size: 32px;
  color: #FDFDFD;
  line-height: 44px; }

.howitworks h1 > p {
  font-size: 18px;
  color: #EBEBEB; }

.howitworks .stepsWrapper {
  margin-top: 20px; }

.howitworks .stepsWrapper .step {
  margin-bottom: 20px;
  background: none;
  border-radius: 5px;
  height: 300px; }

.howitworks .stepsWrapper .step .icon {
  border-radius: 50%;
  border: 1px solid #ebebeb;
  width: 90px;
  height: 90px;
  line-height: 97px;
  margin: 0 auto;
  background: #068; }

.howitworks .stepsWrapper .step h3 {
  font-family: HelveticaNeue-Thin, Tahoma, Arial;
  text-transform: uppercase;
  color: #FFF;
  font-size: 18px; }

.howitworks .stepsWrapper .step span {
  font-size: 13px;
  color: #888; }

.learnmore {
  padding-top: 20px;
  padding-bottom: 20px; }

/* Community section */
.community {
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 450px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  overflow: hidden; }

.community .communitybox {
  background: rgba(255, 255, 255, 0.8);
  max-width: 50%;
  min-height: 200px;
  margin: 0 auto;
  padding: 20px 20px 60px 20px;
  border-radius: 5px; }

.community .communitybox h2 {
  font-size: 2rem;
  color: #000;
  font-weight: 200; }

/* Footer Section */
.footer {
  background: #323232;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fdfdfd;
  box-shadow: inset 0px 1px 0 #1771A3; }

.footer h3 {
  text-transform: uppercase;
  font-family: HelveticaNeue,'Trebuchet MS', Tahoma, Arial;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 50px; }

.footer .footer-discover .btn {
  margin: 10px; }

.footer .translation select.form-control {
  background: #555555;
  border: 1px solid #6F6F6F;
  color: #FFF;
  border-radius: none !important;
  height: 50px; }

.footer .newsletter p {
  color: #949494; }

.footer .newsletter input {
  padding-right: 48px; }

.footer .newsletter .form-group:last-child {
  margin-left: -48px; }

.footer .newsletter input,
.footer .newsletter button {
  height: 50px; }

.footer .socials .image {
  display: inline-block;
  text-align: left;
  width: 50px;
  margin-right: 10px;
  clear: both; }

.footer .social ul li > a {
  color: #FFFFFF; }

.footer .social ul li:hover {
  opacity: 0.7; }

/* footer copyright */
.footer-copyright {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #3C3C3C;
  color: #fdfdfd;
  font-family: HelveticaNeue-Light, Tahoma, Arial; }

.footer-copyright .scrollup {
  border-radius: 5px;
  height: 32px;
  width: 32px;
  line-height: 33px;
  text-align: center;
  background: #555555;
  border: 1px solid #6F6F6F;
  padding: 7px 9px 12px 9px; }

.footer-copyright .scrollup .fa {
  color: #BEBEBE; }

/* PAGES */
/* Awareness */
.awareness {
  background: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 30px; }
  .awareness .awareness-content-wrapper {
    margin-top: 30px; }
  .awareness .signups.affix-top, .awareness .signups.affix-bottom {
    position: relative; }
  .awareness .signups.affix-bottom {
    margin-bottom: 30px; }
  .awareness .signups.affix {
    position: fixed;
    top: 65px;
    width: 295px; }

/* login section */
.login {
  padding-top: 90px;
  padding-bottom: 90px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

.login .signup {
  padding-left: 30px; }

/* project section */
.project {
  padding-top: 30px;
  padding-bottom: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #F8F8F8; }
  .project a {
    color: #337ab7 !important; }

.project h1 {
  font-family: HelveticaNeue-Bold, Tahoma, Arial;
  font-size: 36px;
  color: #4A4A4A;
  line-height: 44px; }

.project h1 p {
  font-family: HelveticaNeue;
  font-size: 16px;
  color: #9B9B9B;
  line-height: 15px;
  line-height: 22px; }

.project h2 {
  font-weight: 200;
  color: #AAAAAA; }

.project .leftbar .list-group-item:first-child {
  border-top: 0px; }

.project .leftbar .list-group-item:last-child {
  border-bottom: 0px; }

.project .leftbar .list-group-item:first-child,
.project .leftbar .list-group-item:last-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px; }

.project .leftbar .list-group-item {
  background-color: #FFF;
  line-height: 30px;
  padding-left: 20px;
  font-size: 14px;
  border: 1px solid #EBEBEB;
  padding-left: 15px;
  border-left: 5px solid #AEDFFA;
  border-right: 0px; }

.project .leftbar .list-group-item span {
  margin-right: 10px; }

.project .leftbar .list-group-item a:hover {
  text-decoration: none !important; }

.project .leftbar .list-group-item.active,
.project .leftbar .list-group-item.active:focus,
.project .leftbar .list-group-item.active:hover {
  background-color: #FFFFFF;
  border-right: 0px;
  border-left: 5px solid #51BFFA;
  font-weight: 700; }

.project .fields {
  margin-bottom: 20px;
  background: #FFFFFF;
  padding: 10px 20px 20px; }

.project .fields h1 > p {
  font-size: 13px;
  font-family: HelveticaNeue-Light, Tahoma, Arial; }

.project .fields .tab-content {
  padding-top: 20px;
  min-height: 100px; }

.project .fields .tab-content pre {
  color: #8d8d8d;
  font-style: italic;
  font-family: HelveticaNeue-Thin;
  font-weight: 200;
  border: 0px; }

.project .fields .tab-content .badge {
  padding: 10px 15px;
  background: none; }

.project .fields .tab-content .badge.yookos {
  border: 1px solid #068;
  color: #068; }

.project .fields .tab-content .badge.facebook {
  border: 1px solid #51BFFA;
  color: #51BFFA; }

.project .fields .tab-content .badge.twitter {
  border: 1px solid #8FD8FF;
  color: #8FD8FF; }

.project .fields .tab-content .badge.instagram {
  border: 1px solid #FFB947;
  color: #FFB947; }

.project .fields .tab-content .badge.google-plus {
  border: 1px solid #FF0000;
  color: #FF0000; }

.project .author {
  position: relative;
  background: #E7F6FF;
  padding: 0 30px 40px 30px;
  color: #444;
  border-radius: 5px;
  box-shadow: inset 0 -1px 0 #ddd; }

.project .author p,
.project .author a {
  color: #444; }

.project .author a:hover {
  color: #068;
  opacity: 0.6; }

.project .author .text-muted {
  color: #777; }

.project .author:before {
  content: '';
  position: absolute;
  left: -35px;
  top: 60px;
  width: 0;
  height: 0;
  border-top: 40px solid #E7F6FF;
  border-left: 40px solid transparent; }

.project .project-description .description,
.project .project-description .author {
  padding-top: 40px; }

/* project updates , uses -> tabs */
.project-updates {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f8f8f8; }

.project-updates #updates,
.project-updates #story {
  padding-top: 70px; }

.project-updates blockquote {
  padding: 0px;
  margin: 0px;
  border-left: none; }

.project-updates .backers li {
  margin-bottom: 10px; }

.project-updates .item .panel-body {
  padding: 0px; }

#project-updates-menu {
  background: #fff;
  box-shadow: 0 1px #ddd; }

#project-updates-menu.affix {
  position: fixed;
  top: 50px;
  left: 0px;
  right: 0px;
  z-index: 100; }

#project-updates-menu.affix + .container:nth-child(2) {
  padding-top: 60px; }

#project-updates-menu .navbar {
  margin: 0px; }

#project-updates-menu .navbar-form {
  margin-top: 11px; }

#project-updates-menu .navbar-nav {
  margin-bottom: 0px; }

#project-updates-menu .navbar-nav li a {
  padding: 20px;
  color: #777;
  font-size: 18px;
  font-weight: 200; }

#project-updates-menu .navbar-nav .active a {
  box-shadow: 0 3px 0px #51BFFA;
  color: #51BFFA; }

.sponsor-list {
  padding: 10px;
  background-color: #FFF;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px #ddd; }
  .sponsor-list .sponsor-info h4 {
    color: #777;
    font-weight: 900;
    font-family: Arial, Helvetica, sans-serif; }

body .project-action-buttons a:nth-of-type(2), body .project-action-buttons a:nth-of-type(2):hover, body .project-action-buttons a:nth-of-type(2):active {
  background-color: #51BFFA;
  border-color: #31a4e2;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -ms-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

/* Profile page */
.profile {
  padding-top: 0px;
  padding-bottom: 30px; }
  .profile .profile-sidebar-wrapper {
    position: relative !important; }
  .profile .profile-sidebar.affix-top {
    position: relative; }
  .profile .profile-sidebar.affix {
    position: fixed !important;
    top: 65px;
    width: 262px; }

.profile .profile-wall-photo-wrapper {
  background: transparent;
  min-height: 370px; }

.profile-wall-photo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 470px;
  background: rgba(2, 2, 2, 0.4); }

.profile .header {
  font-size: 28px;
  color: #FDFDFD; }

/* Profile wall */
.profile .profile-wall {
  margin-top: -70px;
  background: #F8F8F8;
  padding-top: 10px;
  border-radius: 5px; }
  .profile .profile-wall .panel {
    border: 1px solid #69bcea !important;
    border-radius: 5px;
    box-shadow: none; }

.profile .profile-wall #myTab {
  border-bottom: 0px;
  margin-top: -80px; }

.profile .profile-wall #myTab > li {
  margin: 0px; }

.profile .profile-wall #myTab > li > a {
  padding: 10px 25px;
  font-size: 14px;
  box-shadow: none;
  color: #51BFFA; }

.profile .profile-wall #myTab > li.active > a {
  border-radius: 0 0 10px 10px;
  background: #959595;
  color: #FFF !important;
  position: relative; }

.profile-wall .avatar-round {
  width: 138px;
  height: 138px; }
  .profile-wall .avatar-round .img-thumbnail {
    padding: 2px; }

.profile-wall .header .responsive-nav .dropdown {
  position: relative;
  top: -10px;
  z-index: 1; }

.profile-wall .header .responsive-nav .dropdown:not(.open) .dropdown-toggle {
  color: #fff; }

.profile .profile-wall #tab-content-parent {
  background: #fff;
  margin-top: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 40px; }

.profile .profile-wall #tab-content-parent > .tab-pane.active {
  background: none; }

.profile #tab-content-parent > .pane {
  padding: 20px; }
  .profile #tab-content-parent > .pane .list-group-item:first-child:not(.no-bg) {
    background: #3097D1;
    color: #fff; }
    .profile #tab-content-parent > .pane .list-group-item:first-child:not(.no-bg) a {
      color: #fff; }
    .profile #tab-content-parent > .pane .list-group-item:first-child:not(.no-bg) span {
      color: yellow; }

/* events */
.events .event-details .thumbnail {
  height: 150px;
  border-radius: 0px;
  padding: 0 10px; }

.events .event-details .thumbnail.first {
  border-radius: 0 0 0 5px;
  border-right: none; }

.events .event-details .thumbnail.last {
  border-radius: 0 0 5px 0;
  border-left: none; }

.events .attendees .list-unstyled li {
  margin-bottom: 5px; }

/* category */
.category {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fff !important; }

.category .header {
  margin-bottom: 50px; }

/* subheader */
.subheader {
  background: url(/images/banner-12.jpg) no-repeat;
  background-size: cover;
  height: 300px;
  overflow: hidden; }

.project-subheader {
  background-size: cover;
  height: 300px;
  overflow: hidden; }

.subheader .data {
  background: transparent;
  color: #B6B5B5;
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 300px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }

.subheader .content {
  max-width: 980px; }

.subheader .content h1 {
  text-transform: capitalize;
  font-family: HelveticaNeue-Bold, Tahoma, Arial;
  font-size: 36px;
  color: #FDFDFD;
  line-height: 44px; }

.subheader .content > p {
  font-family: HelveticaNeue;
  font-size: 16px;
  color: #FFFFFF !important;
  line-height: 15px;
  line-height: 22px; }

.subheader .content h2 {
  font-weight: 200;
  color: #51BFFA !important;
  font-family: HelveticaNeue-Bold, Tahoma, Arial;
  font-size: 36px; }

.subheader .content h2 > p {
  color: #DDD;
  font-size: 18px; }

/* search results */
.search-results {
  background: #F8F8F8; }

.search-results .list-inline li {
  font-size: 12px;
  color: #999; }

.search-results li.list-group-item {
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin: 5px 0px;
  padding: 0px; }

.search-results li.list-group-item .media-body {
  padding: 5px 0px; }

.search-results .circle strong {
  font-weight: 200;
  font-size: 10px; }

/* Payments */
.payments {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #f5f5f5; }

/* Dashboard */
.dashboard {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #f5f5f5; }

.dashboard-header {
  margin-top: 0px;
  margin-bottom: 0px;
  box-shadow: inset 0 -1px 0 #DDD;
  padding: 15px 10px; }

.dashboard-header button {
  padding: 3px 10px; }

.dashboard .dashboard-content {
  margin-left: -10px; }

.dashboard .dashboard-content .stats .huge {
  font-size: 12px;
  font-weight: 200; }

.dashboard .dashboard-search .form-control,
.dashboard .dashboard-search .btn {
  border-radius: 0px; }

.dashboard .dashboard-menu {
  box-shadow: 1px 0px 0 #ccc;
  background: transparent; }

.dashboard .dashboard-menu .nav-pills > li > a {
  border-radius: 0px;
  color: #9d9d9d;
  font-weight: 200; }

.dashboard .dashboard-menu .nav-pills > li > a .label {
  position: relative;
  top: -8px; }

.dashboard .dashboard-menu .nav-pills > li a .fa {
  position: relative;
  top: 3px;
  color: #BEBEBE; }

.dashboard .dashboard-menu .nav-pills li a:hover {
  opacity: 0.7; }

.dashboard .dashboard-menu .nav-pills > li.active > a {
  box-shadow: none;
  background: none;
  font-weight: bold;
  color: #3097D1; }

.dashboard .dashboard-menu .nav-pills > li.active > a .fa {
  color: #3097D1; }

.dashboard .dashboard-menu .nav-pills > li > ul > li > a {
  margin-left: 30px;
  color: #9d9d9d;
  font-weight: 200; }

.dashboard .dashboard-menu .nav-pills .in .active > a {
  box-shadow: inset -3px 0 0 #3097D1;
  color: #3097D1; }

.dashboard .huge {
  font-size: 14px; }

/* dashboard body - inner pages body */
.dashboard .dashboard-content .dashboard-body {
  padding: 10px; }

.dashboard .dashboard-body .analytics {
  background: #F8F8F8;
  min-height: 250px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: none; }

.dashboard .dashboard-body .small-header {
  color: #bebebe; }

/* Messages */
.msg_container_base {
  background: #e5e5e5;
  margin: 0;
  padding: 0 10px 10px;
  overflow-x: hidden; }

.msg_receive {
  padding-left: 0;
  margin-left: 0; }

.msg_sent {
  padding-bottom: 20px !important;
  margin-right: 0; }

.messages {
  background: white;
  padding: 10px;
  border-radius: 2px;
  max-width: 100%; }

.messages > p {
  font-size: 13px;
  margin: 0 0 0.2rem 0; }

.messages > time {
  font-size: 11px;
  color: #ccc; }

.msg_container {
  padding: 5px 15px;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex; }

.msg_container_base img {
  display: block;
  width: 100%; }

.avatar {
  position: relative; }

.base_receive > .avatar:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 5px solid #FFF;
  border-left-color: transparent;
  border-bottom-color: transparent; }

.base_sent {
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.base_sent > .avatar:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid white;
  border-right-color: transparent;
  border-top-color: transparent; }

.msg_sent > time {
  float: right; }

.msg_container_base::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5; }

.msg_container_base::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5; }

.msg_container_base::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555; }

.btn-group.dropup {
  position: fixed;
  left: 0px;
  bottom: 0; }

/* THIRD PARTY  */
/* jtr */
.jtr {
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: -2; }

.iconmelon,
.im {
  position: relative;
  width: 150px;
  height: 150px;
  display: block;
  fill: #525151; }

.iconmelon:after,
.im:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/* timeline */
#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em; }

#cd-timeline::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #d7e4ed; }

@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em; }
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px; } }

.cd-timeline-block {
  position: relative;
  margin: 2em 0; }

.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both; }

.cd-timeline-block:first-child {
  margin-top: 0; }

.cd-timeline-block:last-child {
  margin-bottom: 0; }

@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0; }
  .cd-timeline-block:first-child {
    margin-top: 0; }
  .cd-timeline-block:last-child {
    margin-bottom: 0; } }

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05); }

.cd-timeline-img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px; }

.cd-timeline-img.cd-picture {
  background: #75ce66; }

.cd-timeline-img.cd-movie {
  background: #c03b44; }

.cd-timeline-img.cd-location {
  background: #f0ca45; }

@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden; }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden; }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s; } }

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5); }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #d7e4ed; }

.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both; }

.cd-timeline-content h2 {
  color: #303e49; }

.cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  font-size: 13px; }

.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  display: inline-block; }

.cd-timeline-content p {
  margin: 1em 0;
  line-height: 1.6; }

.cd-timeline-content .cd-read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em; }

.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb; }

.cd-timeline-content .cd-date {
  float: left;
  padding: .8em 0;
  opacity: .7; }

.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white; }

@media only screen and (min-width: 768px) {
  .cd-timeline-content h2 {
    font-size: 20px; }
  .cd-timeline-content p {
    font-size: 16px; }
  .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    font-size: 14px; } }

@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%; }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white; }
  .cd-timeline-content .cd-read-more {
    float: left; }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px; }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right; }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white; }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right; }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right; }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden; }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s; } }

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s; } }

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px); }
  100% {
    -webkit-transform: translateX(0); } }

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px); }
  100% {
    -webkit-transform: translateX(0); } }

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

/* Media Queries */
/* Large desktops and laptops */
@media (min-width: 992px) {
  body .row-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; } }

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  #project-updates-menu .navbar-nav li {
    float: left;
    font-size: 10px; } }

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  #project-updates-menu .navbar-nav li {
    float: left;
    font-size: 10px; } }

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .project-stats .stats {
    padding-top: 20px;
    text-align: center; }
    .project-stats .stats .circle {
      margin: 0 auto; }
  #project-updates-menu .navbar-nav li {
    float: left;
    font-size: 10px; }
  .community .communitybox {
    width: 1080px !important; }
    .community .communitybox .btn {
      font-size: 10px;
      margin: 5px; } }

/*Payment Button*/
form.local-gateway button,
form#payment-form button,
form input[type=submit] {
  background-color: #4e9af9;
  color: white; }

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .project-stats .stats {
    padding-top: 20px;
    text-align: center; }
    .project-stats .stats .circle {
      margin: 0 auto; }
  .profile-wall .avatar-round {
    top: 20px;
    bottom: 20px; }
  #project-updates-menu .navbar-nav li {
    float: left;
    font-size: 10px; } }

.position-relative {
  position: relative; }

.campaign-sponsor-btn {
  position: absolute;
  top: 25px;
  left: 0px; }

.item .panel-footer .excerpt {
  position: relative;
  height: 4.2em;
  /* exactly three lines 3.9 */
  overflow: hidden;
  font-size: 12px !important; }

.item .panel-footer .excerpt:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 90%); }

.excerpt .excerpt {
  position: relative;
  height: 4.2em;
  /* exactly three lines 3.9 */
  overflow: hidden;
  font-size: 12px !important; }

.excerpt .excerpt:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 90%); }

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

@media (max-width: 767px) {
  .signups.affix {
    position: relative !important; } }

/* FAQ */
.faq-wrapper {
  background-color: #ffffff;
  padding: 40px; }

.faq-list-item {
  position: relative;
  margin-bottom: 10px; }
  .faq-list-item .faq-list-item-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #3097d1;
    color: #ffffff;
    border-radius: 3px;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer; }
    .faq-list-item .faq-list-item-header h2 {
      margin: 0; }
    .faq-list-item .faq-list-item-header span, .faq-list-item .faq-list-item-header h2 {
      font-size: 20px; }
  .faq-list-item .faq-list-item-content {
    padding: 10px;
    box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    display: none; }
    .faq-list-item .faq-list-item-content h6 {
      font-weight: bold;
      margin: 10px 0; }

.faq-list-item.active .faq-list-item-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.faq-list-item.active .faq-list-item-content {
  display: block; }

.gifting-info {
  position: relative; }
  .gifting-info #landingPage {
    overflow-y: scroll;
    max-height: calc(100vh - 50px);
    width: 100%; }
    .gifting-info #landingPage .close-btn {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 30px;
      background-color: red;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      right: 50px;
      cursor: pointer; }
      .gifting-info #landingPage .close-btn span {
        color: #ffffff;
        font-size: 45px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-block;
        margin-top: -5px; }
      @media (max-width: 768px) {
        .gifting-info #landingPage .close-btn {
          right: 10px;
          top: 15px;
          width: 30px;
          height: 30px; }
          .gifting-info #landingPage .close-btn span {
            font-size: 35px; } }
  .gifting-info h2 {
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6); }
  .gifting-info .wrapper {
    display: -ms-flexbox;
    display: flex; }
    .gifting-info .wrapper .product-lists {
      -ms-flex: 1;
      flex: 1; }
      .gifting-info .wrapper .product-lists .product-item {
        padding: 15px;
        background-color: #ffffff;
        margin-bottom: 10px;
        font-weight: bold;
        cursor: pointer;
        height: 60px; }
        .gifting-info .wrapper .product-lists .product-item.active {
          background-color: #fffdb8;
          position: relative; }
          @media (min-width: 769px) {
            .gifting-info .wrapper .product-lists .product-item.active::after {
              content: "";
              border: 20px solid transparent;
              border-right-color: #ffffff;
              position: absolute;
              right: -20px;
              top: 10px; } }
        .gifting-info .wrapper .product-lists .product-item p {
          text-align: center;
          font-size: 20px;
          color: #000000;
          margin: 0; }
      .gifting-info .wrapper .product-lists .product-item-description {
        display: none;
        margin-bottom: 5px; }
        .gifting-info .wrapper .product-lists .product-item-description.active {
          background-color: #ffffff;
          border-radius: 3px;
          padding: 10px; }
          @media (max-width: 768px) {
            .gifting-info .wrapper .product-lists .product-item-description.active {
              display: block; } }
    .gifting-info .wrapper .product-description-global {
      -ms-flex: 2;
      flex: 2;
      margin-left: 20px;
      background-color: #ffffff;
      border-radius: 3px;
      padding: 10px;
      display: none; }
      @media (min-width: 769px) {
        .gifting-info .wrapper .product-description-global {
          display: block; } }
  .gifting-info,
  .gifting-info .sub-product-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-bottom: 10px; }
    .gifting-info *,
    .gifting-info .sub-product-item * {
      box-sizing: border-box; }
    @media (max-width: 768px) {
      .gifting-info,
      .gifting-info .sub-product-item {
        -ms-flex-direction: column;
        flex-direction: column; } }
    .gifting-info .sub-product-image,
    .gifting-info .sub-product-item .sub-product-image {
      width: 100%; }
      @media (max-width: 768px) {
        .gifting-info .sub-product-image,
        .gifting-info .sub-product-item .sub-product-image {
          padding: 10px; } }
      @media (min-width: 769px) {
        .gifting-info .sub-product-image,
        .gifting-info .sub-product-item .sub-product-image {
          position: relative;
          width: 25%;
          margin-left: 30px;
          margin-top: 5px; }
          .gifting-info .sub-product-image::before,
          .gifting-info .sub-product-item .sub-product-image::before {
            content: "";
            position: absolute;
            width: 25px;
            height: 25px;
            background-color: #51bff9;
            border-radius: 25px;
            top: calc(50% - 12.5px);
            left: -32.5px; }
          .gifting-info .sub-product-image::after,
          .gifting-info .sub-product-item .sub-product-image::after {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: #ffffff;
            border-radius: 15px;
            top: calc(50% - 7.5px);
            left: -27.5px; } }
      .gifting-info .sub-product-image img,
      .gifting-info .sub-product-item .sub-product-image img {
        width: 100%; }
    .gifting-info .sub-product-description,
    .gifting-info .sub-product-item .sub-product-description {
      width: 100%;
      padding: 10px; }
      @media (min-width: 769px) {
        .gifting-info .sub-product-description,
        .gifting-info .sub-product-item .sub-product-description {
          width: 75%;
          padding-top: 0px; } }

/*# sourceMappingURL=app.css.map */
