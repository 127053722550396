.footable > thead > tr > th > span.footable-sort-indicator {
  width: 16px;
  height: 16px;
  display: block;
  float:right;
  background: url('img/sorting_sprite.png') no-repeat top left;
}

.footable > thead > tr > th.footable-sortable:hover {
  cursor:pointer;
}

.footable > thead > tr > th.footable-sortable > span {
  
}

.footable > thead > tr > th.footable-sorted > span.footable-sort-indicator {
  background-position: 0 -16px;
}

.footable > thead > tr > th.footable-sorted-desc > span.footable-sort-indicator {
  background-position: 0 -32px;
}

